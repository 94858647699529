/* Portfolio.css */

/* Grille en flexbox avec flex-wrap pour les cartes */
.portfolio-grid {
  display: flex;
  flex-wrap: wrap; /* Les éléments se répartissent sur plusieurs lignes si nécessaire */
  justify-content: center; /* Centre les cartes horizontalement */
  gap: 20px; /* Espace harmonieux entre les cartes */
  padding: 20px; /* Espace autour de la grille */
}

.portfolio-container {
  margin-top: 100px;
  margin-bottom: 100px;
}
