/* StepsSection.css */
.steps-section {
  text-align: center;
  padding: 50px 20px;
  margin-top: 100px;
  background-color: #f8f9fc; /* Light background color for contrast */
}

.steps-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 100px;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.step {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 400px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.step:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.step h3 {
  font-size: 1.5rem;
  color: #1a73e8; /* Highlight color for the steps */
  margin-bottom: 10px;
}

.step p {
  font-size: 1.3rem;
  line-height: 1.6;
}

.checklist-icon {
  width: 75px;
  height: 75px;
}

.draw-icon {
  width: 75px;
  height: 75px;
  margin-bottom: 20px;
}

.receive {
  margin-top: 0px;
}

@media (min-width: 768px) {
  .steps {
    flex-direction: row; /* Align steps in a row on larger screens */
  }
}
