/* Styles généraux de la navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

/* Styles des liens transformés en boutons sans fond ni bordure */
.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

/* Style pour les boutons de navigation (Portfolio, Pricing, FAQ) */
.navbar-links button {
  background: none; /* Pas de fond */
  border: none; /* Pas de bordure */
  padding: 10px 20px;
  color: #333; /* Texte en noir */
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease; /* Animation de la couleur au survol */
}

.navbar-links button:hover {
  color: #5a67d8; /* Couleur violet lors du survol */
}

/* Style prioritaire pour le bouton "See Plans" dans navbar */
.navbar-links .btn-primary {
  padding: 10px 20px;
  background-color: #5a67d8; /* Couleur violet permanent */
  color: white; /* Texte blanc permanent */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  font-size: large;
}

/* Assurez-vous que le hover ne change rien pour "See Plans" */
.navbar-links .btn-primary:hover {
  background-color: #5a67d8; /* Couleur constante */
  color: white; /* Texte constant */
}

/* Styles pour le menu burger */
.navbar-burger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
}

.burger-line {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s;
}

.burger-line.open:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-line.open:nth-child(2) {
  opacity: 0;
}

.burger-line.open:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Menu mobile */
.navbar-links-mobile {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: white;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar-links-mobile li {
  display: block;
}

.navbar-links-mobile button {
  text-align: left;
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

.navbar-links-mobile button:hover {
  color: #5a67d8;
}

/* Media query pour mobile */
@media (max-width: 767px) {
  .navbar-links {
    display: none;
  }

  .navbar-burger {
    display: flex;
  }
}

/* Styles par défaut pour le logo desktop */
.logo-desktop {
  display: block;
  width: 180px;
}

/* Masquer le logo mobile par défaut */
.logo-mobile {
  display: none;
  width: 40px;
}

/* Media query pour les écrans mobiles */
@media (max-width: 767px) {
  .logo-desktop {
    display: none; /* Masquer le logo desktop */
  }

  .logo-mobile {
    display: block; /* Afficher le logo mobile */
  }
}
