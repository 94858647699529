.pricing-section {
  padding: 60px 20px;
  background-color: #f7fafc;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.pricing-section h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #333333;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.pricing-card {
  flex: 1 1 300px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 50px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
}

.pricing-card h3 {
  font-size: 36px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  margin-top: 0px;
}

.price-strike {
  font-size: 18px;
  color: #999;
  text-decoration: line-through;
}

.price-current {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.price-dollar {
  font-size: 25px;
}

.price-tag {
  background-color: #e53e3e;
  color: white;
  padding: 3px 6px;
  border-radius: 5px;
  margin-left: 25px;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.pricing-card ul li {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.pricing-card ul li::before {
  content: "✔ ";
  color: #48bb78;
  font-weight: 700;
  font-size: 1.2em; /* Augmente la taille des ticks */
}

/* Apply the '✘' content only to the first two pricing cards */
.pricing-cards
  .pricing-card:not(.premium)
  ul
  li:nth-last-child(-n + 3)::before {
  content: "✘ ";
  color: #e53e3e;
  font-weight: 700;
  font-size: 1.2em; /* Augmente la taille des ticks */
}

.pricing-card .btn-primary {
  padding: 10px 20px;
  background-color: #5a67d8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}

.pricing-card .btn-primary:hover {
  background-color: #4c51bf;
}

.premium .most-popular-label {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6b46c1;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.pricing-card.premium {
  border: 3px solid #6b46c1;
}

.bold-text {
  font-weight: 700 !important;
}

/* Style du badge "Most Popular" */
.badge-popular {
  background-color: #6b46c1;
  color: white;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 12px;
  margin-left: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.premium-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
