.carousel {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.carousel-row {
  display: flex;
  white-space: nowrap;
  will-change: transform;
  position: relative;
  transform: translateX(0);
}

.card {
  flex: 0 0 auto;
  width: 350px;
  height: 225px;
  /* background-color: #f0f0f0; */
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.card-logo {
  max-width: 75%;
  max-height: 100%;
  object-fit: contain;
}

.flexio {
  /* background-color: #effaff; */
  background-color: #e2eafc;
}

.snapi {
  /* background-color: #fff5ff; */
  background-color: #f7ebff;
}

.linkup {
  /* background-color: #d7f8db; */
  background-color: #f0f0f0;
}

.advize {
  /* background-color: #fff5f9; */
  background-color: #f0effa;
}

.advize-size {
  max-width: 150%; /* Exemple de style pour agrandir spécifiquement l'image advize */
}

.akkhar-white {
  background-color: black;
}

.akkhar-red {
  background-color: #dfe0df;
}

.awdia {
  background-color: #094565;
}

.fek-white {
  background-color: black;
}

.swift {
  background-color: #094565;
}

.poly {
  background-color: #fcf8ff;
}

.wokoma {
  background-color: #bb7b6b;
}
.intact {
  background-color: #dfe0df;
}
