/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Assurez-vous que le html et body prennent toute la hauteur */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* L'App doit utiliser Flexbox et occuper toute la hauteur de la fenêtre */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Hauteur minimum égale à la hauteur de la fenêtre */
}

/* Le contenu principal doit utiliser flex-grow pour occuper tout l'espace restant */
.main-content {
  flex-grow: 1; /* Prend l'espace disponible pour pousser le footer vers le bas */
}

/* Styles pour centrer le contenu des pages Success et Cancel */
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Pour occuper toute la hauteur de la vue */
  text-align: center; /* Pour centrer le texte horizontalement */
  margin-top: 20px;
}

/* Footer simple, sans largeur forcée */
footer {
  padding: 1rem;
  background-color: #f1f1f1;
  text-align: center;
}

/* Vos styles existants */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.btn-homepage {
  background-color: #5a67d8;
  color: white;
  width: 200px;
  height: 60px;
  font-size: large;
  border-radius: 5px;
  border: none;
  margin-top: 20px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
