/* Header.css
.header {
  text-align: center;
  padding: 60px 20px;
  background-color: #f7fafc;
  margin-top: 100px;
  margin-bottom: 150px;
}

.header h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.header .highlight {
  color: #6b46c1;
}

.header p {
  font-size: 27px;
  margin-bottom: 40px;
}

.header-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn-secondary {
  padding: 15px 35px;
  background-color: #e2e8f0;
  color: #333333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: large;
}

.btn-secondary:hover {
  background-color: #cbd5e0;
}

.btn-primary {
  padding: 15px 40px;
  background-color: #5a67d8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: large;
}

.btn-primary:hover {
  background-color: #4c51bf;
} */

/* Styles pour le conteneur du header */
.header {
  text-align: center;
  padding: 60px 20px;
  background-color: #f7fafc;
  margin-top: 100px;
  margin-bottom: 150px;
}

.header h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
}

.header .highlight {
  color: #6b46c1;
}

.header p {
  font-size: 27px;
  margin-bottom: 40px;
}

.header-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

/* Harmonisation des boutons (btn-secondary et btn-primary) */
.btn-secondary,
.btn-primary {
  width: 200px; /* Largeur fixe pour les deux boutons */
  height: 60px; /* Hauteur fixe pour les deux boutons */
  display: flex;
  align-items: center;
  justify-content: center; /* Centrer le texte à l'intérieur des boutons */
  font-size: large;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none; /* Retire le soulignement des textes */
}

/* Style pour le bouton "See Plans" */
.btn-secondary {
  background-color: #e2e8f0;
  color: #333333;
}

.btn-secondary:hover {
  background-color: #cbd5e0;
}

/* Style pour le bouton "Order Now" */
.btn-primary {
  background-color: #5a67d8;
  color: white;
}

.btn-primary:hover {
  background-color: #4c51bf;
}
.header-buttons a {
  text-decoration: none; /* Enlève le soulignement des liens */
}

@media (max-width: 525px) {
  .header-buttons {
    flex-wrap: wrap;
  }
}
