.faq-section {
  padding: 40px;
  background-color: #f7fafc;
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 20px;
}

.faq-list {
  max-width: 400px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 15px;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-item h3 {
  margin: 0;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-weight: 300;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Initially hide the answer completely */
.faq-answer {
  display: none; /* Hide the answer when the FAQ is collapsed */
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Show the answer only when it's open */
.faq-answer.show {
  display: block; /* Make the answer visible when expanded */
  opacity: 1;
  padding-top: 10px;
  padding: 15px;
}
